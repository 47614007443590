import React, { useState, useEffect } from "react";
import { generateByLambda, deleteByLambda, fetchHistoryByLambda } from "../services/awsService";
import HistoryItem from "../components/HistoryItem/HistoryItem";
import './UploadPage.css';

const getTokensAndEmailFromUrl = () => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = hashParams.get("access_token");
    const idToken = hashParams.get("id_token");

    let email = null;
    if (idToken) {
        const base64Url = idToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedPayload = JSON.parse(atob(base64));
        email = decodedPayload.email;
    }

    return { accessToken, email };
};

const { accessToken, email } = getTokensAndEmailFromUrl();

if (accessToken) localStorage.setItem("access_token", accessToken);
if (email) localStorage.setItem("email", email);

const UploadPage = () => {
    const [file, setFile] = useState(null);
    const [history, setHistory] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [selectedMood, setSelectedMood] = useState("happy");
    const [selectedStyle, setSelectedStyle] = useState("poem");
    const [isGenerating, setIsGenerating] = useState(false);
    
    useEffect(() => {
        const loadHistory = async () => {
            try {
                const historyData = await fetchHistoryByLambda(email, accessToken);
                setHistory(historyData);
            } catch (error) {
                console.error("Error fetching history:", error);
            } finally {
                setLoadingHistory(false);
            }
        };
    
        loadHistory();
    }, [email, accessToken]);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 4 * 1024 * 1024) { // 4MB 限制
                alert("File size exceeds 4MB. Please select a smaller file.");
                event.target.value = ""; // 清空文件输入框
                setFile(null);
            } else {
                setFile(file);
            }
        }
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        setIsGenerating(true); // 开始生成

        try {
            const localImageURL = URL.createObjectURL(file);
            const response = await generateByLambda(file, selectedStyle, selectedMood, accessToken, email);

            const newHistoryItem = {
                id: response.id,
                image: localImageURL,
                photo_url: response.photo_url,
                generatedText: response.generated_text,
                mood: selectedMood,
                style: selectedStyle,
                timestamp: response.timestamp,
            };
            setFile(null);          
            setHistory((prevHistory) => [newHistoryItem, ...prevHistory]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsGenerating(false); // 结束生成
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteByLambda(id, accessToken);
            setHistory((prevHistory) => prevHistory.filter((item) => item.id !== id));
            console.log(`Deleted item with ID: ${id}`);
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    return (
        <div className="upload-page">
            <header className="header">
                <h1 className="title">Poetagram</h1>
                <p className="slogan">Capture your moments and add poetic captions to your photos.</p>
            </header>
            <div className="main-container">
                <div className="form-container">
                    <form onSubmit={handleUpload}>
                        <div className="upload-container">
                            <label className="file-input-label">Select a photo:</label>
                            <div className="file-input-container">
                                <input
                                    type="file"
                                    accept="image/jpeg, image/png, image/jpg"
                                    onChange={handleFileChange}
                                    required
                                    className="file-input"
                                    id="file-upload"
                                />
                                <label htmlFor="file-upload" className="custom-file-input">
                                    {file ? file.name : "Choose File"}
                                </label>
                            </div>
                        </div>
                        <div className="mood-style-container">
                            <div className="mood-container">
                                {["happy", "nostalgic", "dramatic", "romantic", "energetic", "philosophical"].map((moodOption) => (
                                    <span
                                        key={moodOption}
                                        className={`mood-tag ${selectedMood === moodOption ? 'selected' : ''}`}
                                        onClick={() => setSelectedMood(moodOption)}
                                    >
                                        {moodOption}
                                    </span>
                                ))}
                            </div>
                            <div className="style-container">
                                {["poem", "film quote", "book quote", "lyric", "tale", "joke "].map((styleOption) => (
                                    <span
                                        key={styleOption}
                                        className={`style-tag ${selectedStyle === styleOption ? 'selected' : ''}`}
                                        onClick={() => setSelectedStyle(styleOption)}
                                    >
                                        {styleOption}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="generate-button" type="submit" disabled={isGenerating}>
                                {isGenerating ? "" : "Generate"}
                                {isGenerating && <span className="loading-dots">...</span>}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="history-container">
                    <h2 className="history-title">History</h2>
                    <div className="history-content">
                        <ul>
                            {history.map((item) => (
                                <HistoryItem key={item.id} item={item} onDelete={handleDelete} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <p>© 2024 Guo Fan. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default UploadPage;
