import React, { useState } from "react";
import './HistoryItem.css';

const HistoryItem = ({ item, onDelete }) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleDelete = () => {
        setIsVisible(false);
        setTimeout(() => {
            onDelete(item.id, item.photo_url);
        }, 300);
    };

    return (
        <li className={`history-item ${isVisible ? '' : 'fade-out'}`}>
            <div className="history-row">
                <img
                    src={item.image}
                    alt="Uploaded Thumbnail"
                    className="history-image"
                />
                <div className="history-info">
                    <span className="mood-label">{item.mood}</span>
                    <span className="style-label">{item.style}</span>
                    <p>
                        {item.timestamp}
                    </p>
                </div>
            </div>
            <p className="generated-text">
                {item.generatedText}
            </p>
            <button className="delete-button" onClick={handleDelete}>Delete</button>
        </li>
    );
};

export default HistoryItem;
