const config = {
    userPoolDomain: "https://guofan.auth.us-east-1.amazoncognito.com",
    clientId: "39rtvfv76e5782bkoi59akptl7",
    redirectUri: process.env.NODE_ENV === "development"
        ? "http://localhost:3000/upload"
        : "https://guofan.engineer/upload",
    region: "us-east-1"
};

export default config;
