import React from 'react';
import config from '../config';
import './HomePage.css';

const HomePage = () => {

    const handleGetStarted = () => {
        const cognitoDomain = config.userPoolDomain; 
        const clientId = config.clientId; 
        const redirectUri = config.redirectUri; 

        const signInUrl = `${cognitoDomain}/oauth2/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20email%20profile`;
        console.log("signInUrl:",signInUrl);
        window.location.href = signInUrl;
    };

    return (
        <div className="homepage">
            <header className="header">
                {/* 移除 img 标签 */}
            </header>
            <div className="content">
                <h1>Welcome to Poetagram</h1>
                <h2>Capture your moments and add poetic captions to your photos.</h2>
                <button className="get-started" onClick={handleGetStarted}>Get Started</button>
            </div>
            <footer className="footer">
                <p>© 2024 Guo Fan. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default HomePage;
