export const generateByLambda = async (file, style, mood, accessToken, email) => {
    const apiGatewayUrl = 'https://kn7wsfczpd.execute-api.us-east-1.amazonaws.com/dev/generate'; // 替换为最新的 API Gateway URL

    // 转换文件为 Base64
    const fileToBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(",")[1]); // 去掉 `data:image/jpeg;base64,` 前缀
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });

    try {
        const base64File = await fileToBase64(file);
        const contentType = file.type; // 从文件对象中获取 MIME 类型

        // 创建请求体
        const body = JSON.stringify({
            file: base64File,
            content_type: contentType, // 动态添加 MIME 类型
            style: style,
            mood: mood,
            email: email,
        });

        // 发送 POST 请求
        const response = await fetch(apiGatewayUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`,
            },
            body: body,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Response from AWS:", data);
        return data;
    } catch (error) {
        console.error("Error uploading to AWS:", error);
        throw error;
    }
};


export const deleteByLambda = async (id, accessToken) => {
    const apiGatewayUrl = "https://kk0nujblkg.execute-api.us-east-1.amazonaws.com/dev/delete-history"; 
    try {
        const body = JSON.stringify({ id });

        const response = await fetch(apiGatewayUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: body,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Delete response from AWS:", data);
        return data;
    } catch (error) {
        console.error("Error deleting from AWS:", error);
        throw error;
    }
};

export const fetchHistoryByLambda = async (email, accessToken) => {
    const apiGatewayUrl = "https://9uvhisgsq3.execute-api.us-east-1.amazonaws.com/dev/fetch-history"; 

    try {
        const body = JSON.stringify({ email });

        const response = await fetch(apiGatewayUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: body,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Fetch history response from AWS:", data);
        return data.history; // Return the `history` field from the response
    } catch (error) {
        console.error("Error fetching history from AWS:", error);
        throw error;
    }
};